import React from 'react';
import PaymentPoints from '../PaymentPoints/PaymentPoints';

const PointsBolivia = () => {
  return (
    <div className="landing-page">
      <PaymentPoints countryCode="BO" countryName="Bolivia" />
    </div>
  );
};

export default PointsBolivia;
