import React from 'react';
import { graphql, PageProps } from 'gatsby';
import PointsBolivia from '../../components/Landing/PointsBolivia';
import SEO from '../../components/SEO';

const PAGE_TITLE = 'Puntos de retiro en efectivo Bolivia';
const PAGE_DESCRIPTION =
  'Envía dinero a Bolivia con CurrencyBird y retira en efectivo. Tarifas bajas y muchos puntos de retiro. Consulta nuestro mapa.';

const PointsBoliviaPage: React.FC<PageProps> = () => {
  return (
    <>
      <SEO title={PAGE_TITLE} description={PAGE_DESCRIPTION} />
      <PointsBolivia />
    </>
  );
};

export default PointsBoliviaPage;

export const pageQuery = graphql`
  query PointsBoliviaPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
